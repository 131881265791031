.App {
  /* display: flex; */
  flex-direction: row;
  height: 100vh;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(20, 37, 68);
  background: linear-gradient(0deg, rgba(20, 37, 68,1) 0%, rgb(19, 52, 112) 100%);
}
.background::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0) 60%, rgba(0,0,0,0.75) 100%);
}

.imagesPanel {
  /* width: 400px; */
  overflow-y: auto;
  padding: 30px;
  padding-bottom: 150px;
  text-align: center;
  min-height: 100%;
}

.images {
  margin: 0 auto;
  width: 50%;
  max-width: 400px;
}

.imageBox {
  width: 100%;
  position: relative;
  /* z-index: 0; */
  transition: 750ms transform ease, 750ms margin ease, 0ms z-index linear;
  margin-top: 0%;
  margin-bottom: 0%;
  cursor: pointer;
}
.imageBox.even.selected, .imageBox.odd.selected {
  transform: translateX(0) scale(1.5) rotate(0deg) !important;
  /* z-index: 1; */
  margin-top: 30%;
  margin-bottom: 30%;
}
.imageBox::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: -1;
  border-radius: 15px;
  filter: blur(7px);
  transition: 750ms all ease;
}
.imageBox.even::before {
  transform: translate(-67%, -2%) scale(0.8) skew(0deg, -4deg);
  transform-origin: 100% 100%;
}
.imageBox.odd::before {
  transform: translate(-33%, -2%) scale(0.8) skew(0deg, 4deg);
  transform-origin: 0 100%;
}
.imageBox.even.selected::before, .imageBox.odd.selected::before {
  transform: translate(-50%, 3%) scale(1) skew(0deg, 0deg);
  filter: blur(7px);
}
/* .imageBox.even {
  transform: translateX(-40%) rotate(-10deg);
}
.imageBox.odd {
  transform: translateX(40%) rotate(10deg);
} */
.imageBox.not-selected.even:hover {
  transform: translateX(-40%) rotate(-5deg) !important;
}
.imageBox.not-selected.odd:hover {
  transform: translateX(40%) rotate(5deg) !important;
}
.imageBox.not-selected:hover::before {
  filter: blur(12px);
}
.imageBox.not-selected.even:hover::before {
  transform: translate(-67%, 0%) scale(0.8) skew(0deg, -4deg);
}
.imageBox.not-selected.odd:hover::before {
  transform: translate(-33%, 0%) scale(0.8) skew(0deg, 4deg);
}
.imagesPanel .images {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}
.imagesPanel.fade-out .images {
  opacity: 0;
}

.image {
  width: 100%;
  display: block;
  border-radius: 10px;
  margin-bottom: -12%;
}
.text {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 20px;
  width: 70%;
  text-align: left;
  color: white;
  text-shadow: 0px 1px 5px rgba(0, 0, 0, 1);
}
.imageBox.odd .text {
  left: auto;
  right: 0;
  text-align: right;
}
.location {
  font-size: .8em;
  font-weight: 900;
}

.title {
  position: fixed;
  top: 0;
  left: 0;
  color: white;
  font-size: 1.5em;
  font-weight: 100;
  padding: 20px;
  background: linear-gradient(115deg, black, transparent);
}

.contentBox {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: linear-gradient(45deg, black 0, transparent 180px);
  pointer-events: none;
}
.content {
  padding: 20px;
  display: inline-block;
  pointer-events: all;
}

.year {
  cursor: pointer;
  text-decoration: none;
  color: white;
  transition: 500ms opacity ease;
  opacity: 0.5;
}
.year:hover {
  opacity: 0.85;
}
.year.selected,
.year.selected:hover {
  font-weight: 600;
  opacity: 1;
}
